<template>
  <!-- Contact page -->
  <div class="contact-div">
    <div>
      <h1 class="contact-title">{{ $t("contact.title") }}</h1>
      <p class="contact-main-text mb-10">
        {{ $t("contact.content") }}
      </p>
    </div>
    <v-row class="mt-5 mb-5" justify="center" align="center">
      <v-card class="pa-10 card-content">
        <v-card-text>
          <a class="mail" href="mailto:hello@kermap.com">hello@kermap.com</a>
          <p class="mt-5 address">
            02 30 96 07 66<br />1137a Avenue des Champs Blancs<br />35510
            Cesson-Sévigné
          </p>
        </v-card-text>
      </v-card>
    </v-row>
    <div v-if="!isMobile">
      <h2 class="mt-10 mb-10">{{ $t("contact.others.title") }}</h2>
      <v-row
        v-for="plateform in platforms"
        :key="plateform.name"
        justify="center"
        align="center"
        class="mb-5"
      >
        <v-col>
          <v-img
            :src="require('@/assets/images/plateformes/' + plateform.image)"
            :lazy-src="
              require('@/assets/images/plateformes/' + plateform.image)
            "
            height="250px"
            width="400px"
            class="image-format"
          >
          </v-img>
        </v-col>
        <v-col>
          <v-card class="card-content" width="40vw" height="250px">
            <v-card-text style="text-align: center">
              <div>
                <p class="name mt-2">{{ plateform.name }}</p>
                <p class="content mt-13" style="text-align: left">
                  {{ plateform.content }}
                </p>
                <v-btn
                  rounded
                  elevation="0"
                  color="#ffd038"
                  class="mt-10"
                  style="text-transform: inherit"
                  :href="plateform.link"
                >
                  <span class="about-visit-website-btn-text">{{
                    $t("contact.button")
                  }}</span>
                  <v-icon class="pl-2" color="#082e6e"
                    >mdi-chevron-right</v-icon
                  >
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      isMobile: undefined,
      selectedPlatform: null,
    };
  },
  computed: {
    platforms() {
      return [
        {
          name: "kermap.com",
          content: this.$t("contact.others.kermap.content"),
          image: "kermap.png",
          link: "https://kermap.com/",
        },
        {
          name: "Nos Villes Vertes",
          content: this.$t("contact.others.nvv.content"),
          image: "nvv.png",
          link: "https://www.nosvillesvertes.fr/",
        },
        {
          name: "Agri.nimbo.earth",
          content: this.$t("contact.others.agri.content"),
          image: "agri.png",
          link: "https://agri.nimbo.earth/",
        },
      ];
    },
  },
  mounted() {
    this.isMobile = window.innerWidth <= 800;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth <= 800;
    });
  },
};
</script>

<style scoped>
.card-content {
  border-radius: 15px;
}

.contact-title {
  font-size: 3vw;
}

.contact-main-text {
  font-size: 0.9vw;
  max-width: 50vw;
}

.mail {
  font-weight: bold;
  text-decoration: underline;
  color: black;
  font-size: 1vw;
}

.address {
  font-size: 1vw;
  color: black;
}

.image-format {
  border-radius: 15px;
}

.name {
  color: black;
  font-weight: bold;
  text-decoration: underline;
  font-size: 18px;
}

.content {
  color: black;
  font-size: 16px;
}

.card-content {
  width: 40vw;
}

@media screen and (max-width: 800px) {
  .contact-title {
    font-size: 24px;
  }

  .contact-main-text {
    font-size: 18px;
    max-width: inherit;
  }

  .card-content {
    width: 300px;
  }

  .mail {
    font-size: 18px;
  }

  .address {
    font-size: 16px;
  }
}
</style>
